<template>
  <div>
    <div class="card card-custom card-stretch gutter-b">
      <div class="card-header">
        <div class="card-title">
          <h3 class="card-label">Ubah Pengguna</h3>
        </div>
      </div>
      <div class="card-body pt-0">
        <div class="mb-6 p-5">
          <div>
            <br />
            <form>
              <div class="form-group row">
                <label class="col-lg-4">Nama</label>
                <div class="col-lg-8">
                  <input
                    type="text"
                    v-model="getuser.name"
                    class="form-control"
                  />
                </div>
              </div>
              <div class="form-group row">
                <label class="col-lg-4">Email</label>
                <div class="col-lg-8">
                  <input
                    type="text"
                    v-model="getuser.email"
                    class="form-control"
                  />
                </div>
              </div>
              <div class="form-group row">
                <label class="col-lg-4">No. HP</label>
                <div class="col-lg-8">
                  <input
                    type="text"
                    v-model="getuser.no_hp"
                    class="form-control"
                  />
                </div>
              </div>
              <div class="form-group row">
                <label class="col-lg-4">Username</label>
                <div class="col-lg-8">
                  <input
                    type="text"
                    v-model="getuser.username"
                    class="form-control"
                  />
                </div>
              </div>
              <div class="form-group row">
                <label class="col-lg-4">Password</label>
                <div class="col-lg-8">
                  <input
                    type="password"
                    v-model="getuser.password"
                    class="form-control"
                  />
                </div>
              </div>
              <div class="form-group row">
                <label class="col-lg-4">Role</label>
                <div class="col-lg-8">
                  <s-select
                    label="name"
                    v-model="getuser.name_role"
                    :options="roles"
                  />
                </div>
              </div>
              <div class="form-group row">
                <label class="col-lg-4">Alamat</label>
                <div class="col-lg-8">
                  <textarea
                    type="text"
                    v-model="getuser.alamat"
                    class="form-control"
                  />
                </div>
              </div>
              <div class="form-group row">
                <label class="col-lg-4">Bidang Ilmu</label>
                <div class="col-lg-8">
                  <input
                    type="text"
                    v-model="getuser.bidang_ilmu"
                    class="form-control"
                  />
                </div>
              </div>
              <div class="form-group row">
                <label class="col-lg-4">Pendidikan Terakhir</label>
                <div class="col-lg-8">
                  <input
                    type="text"
                    v-model="getuser.pendidikan_terakhir"
                    class="form-control"
                  />
                </div>
              </div>
              <div class="form-group row">
                <label class="col-lg-4 col-form-label"
                  >Tempat/Tanggal Lahir</label
                >
                <div class="col-lg-8">
                  <div class="row">
                    <div class="col-xl-6">
                      <div class="form-group">
                        <input
                          type="text"
                          v-model="getuser.tempat_lahir"
                          class="form-control"
                        />
                      </div>
                    </div>
                    <div class="col-xl-6">
                      <div class="form-group">
                        <b-form-datepicker
                          v-model="getuser.tanggal_lahir"
                          id="example-datepicker"
                          class="mb-2"
                        ></b-form-datepicker>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div class="card-toolbar float-right">
                <button
                  type="reset"
                  class="btn btn-success mr-2"
                  @click="updateUser()"
                  ref="kt_save_changes"
                  style="background-color: #3699ff"
                >
                  Simpan
                </button>
                <button
                  type="reset"
                  class="btn btn-secondary"
                  @click="cancel()"
                >
                  Kembali
                </button>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import Vue from "vue";
import { SET_BREADCRUMB } from "@/core/services/store/breadcrumbs.module";
import axios from "axios";
import vSelect from "vue-select";
import Swal from "sweetalert2";

Vue.component("s-select", vSelect);

export default {
  name: "ubahPengguna",
  data() {
    return {
      user: JSON.parse(localStorage.getItem("user")),
      url: localStorage.getItem("baseapi"),
      token: localStorage.getItem("id_token"),
      roles: [],
      getuser: {
        name: "",
        email: "",
        no_hp: "",
        username: "",
        password: "",
        name_role: "",
        alamat: "",
        bidang_ilmu: "",
        pendidikan_terakhir: "",
        tempat_lahir: "",
        tanggal_lahir: null,
      },
      originalUser: {}, // Penyimpanan data asli sebelum perubahan
    };
  },

  mounted() {
    this.$store.dispatch(SET_BREADCRUMB, [{ title: "Ubah Pengguna" }]);
    this.getdetail();
    this.getrole();
  },
  methods: {
    getrole() {
      axios
        .get(this.url + "/role", {
          headers: {
            xth: this.token,
          },
        })
        .then((response) => {
          this.roles = response.data.data;
        })
        .catch((error) => {
          console.log(error);
          return error;
        });
    },
    getdetail() {
      axios
        .get(this.url + `/users/` + this.$route.params.id, {
          headers: {
            xth: this.token,
          },
        })
        .then((response) => {
          this.getuser = response.data.data;

          // Save original data for comparison
          this.originalUser = Object.assign({}, this.getuser);
        });
    },

    updateUser() {
      let updatedData = {};

      // Loop through each field to check if it has changed
      for (let key in this.getuser) {
        // Check if the field is not null or empty
        if (this.getuser[key] !== null && this.getuser[key] !== "") {
          // Check if the field value has changed
          if (this.getuser[key] !== this.originalUser[key]) {
            updatedData[key] = this.getuser[key];
          }
        }
      }

      axios
        .put(this.url + `/users/` + this.$route.params.id, updatedData, {
          headers: {
            xth: this.token,
          },
        })
        .then((response) => {
          Swal.fire({
            title: "Berhasil!",
            text: "Data pengguna berhasil diperbarui.",
            icon: "success",
            confirmButtonText: "OK",
          }).then(() => {
            this.$router.push("/users");
          });
        })
        .catch((error) => {
          console.error("Error updating user:", error);
          Swal.fire({
            title: "Error!",
            text: "Gagal memperbarui data pengguna.",
            icon: "error",
            confirmButtonText: "OK",
          });
        });
    },

    cancel() {
      this.$router.push("/users");
    },
  },
};
</script>